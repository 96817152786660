
































































import { Component, Vue } from "vue-property-decorator";
import { RigthsService,  WechatService } from "src/services";
import { Toast } from "vant";

@Component
export default class Power extends Vue
{
    private powerInfo: any={};


    private packageType: any={
        1:1,
        2:2,
        4:3,
        5:4,
        6:5,
        7:6
    };

    /**
     * 获取id
     * @private
     * @returns any
     */
    protected get memberServiceItemId(): any
    {
        return this.$route.query && this.$route.query.memberServiceItemId;
    }

    private async init(): Promise<any>
    {
        if(this.isWechat())
        {
            let url = location.href;
            let {content: result} = await WechatService.instance.getWechatJSSDK(url);
            if(result.data)
            {
                console.log(result.data)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: result.data.appId, // 必填，公众号的唯一标识
                    timestamp: result.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
                    signature: result.data.signature, // 必填，签名
                    jsApiList: ["openLocation"],
                    openTagList: ['wx-open-launch-weapp'] // 必填，需要使用的JS接口列表
                });

                wx.ready(function()
                {
                    
                });
            }
        }
    }

    /**
     * 判断是否数微信环境
     * @private
     * @returns boolean
     */
    private isWechat(): boolean
    {
        let ua = navigator.userAgent.toLowerCase();

        if(ua.indexOf("micromessenger") > -1)
        {
            return true;
        }
        else
        {
            return false;
        }

    }

    private wx_launch(info): void
    {
        if (!this.isWechat()) {
            return
        }
        var btn = document.getElementById(info.eleId); //获取元素
        let script = document.createElement("script");// 创建script内容插槽 避免template标签冲突
        script.type = "text/wxtag-template"; // 使用script插槽 必须定义这个type
        script.text = info.content // 自定义的html字符串内容
        let html = `<wx-open-launch-weapp id="wxBtn" style="width:100%; height:100%;display:block;" username="${info.appid}" path="${info.url}">${script.outerHTML}</wx-open-launch-weapp>`;
        btn.innerHTML = html; // html字符串赋值
        var wxBtn = document.getElementById("wxBtn"); //获取元素
        // 点击按钮 正常跳转触发
        wxBtn.addEventListener("launch", function (e) {
            console.log("success");
        });
        // 点击跳转 抛出异常
        wxBtn.addEventListener("error", function (e) {
            console.log("fail", e);
            alert(`跳转异常 - ${JSON.stringify(e)}`)
        });
    }

    protected  mounted() {
        // 获取详情
        this.getServiceItemInfo(this.memberServiceItemId);
        this.init();

        let reportContent =`
            <button class="test-btn">跳转跳转跳转跳转跳转跳</button>
            <style>
                .test-btn{
                    width: 100%;
                    height: 69px;
                    background: transparent;
                    border: none;
                    color:rgba(255,255,255,0)
                }
                .test-btn:focus{outline: 0}
            </style>
            ` 
        // 报告解读
        let reportParams = {
            appid: "gh_5cdb318e153e",
            eleId:"package", // 元素id
            url: "/pages/index/index", // 跳转小程序的页面路径
            content: reportContent // 自定义的html内容
        };

        this.wx_launch(reportParams);

    }

    /**
     * 获取权益详情
     * @private
     */
    private async getServiceItemInfo(id:string): Promise<void>
    {
        try
        {
            let {content: result} = await RigthsService.instance.getServiceItemInfo(id);
            if(result.data)
            {
                this.powerInfo = result.data;
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 使用权益
     * @private
     * @returns void
     */
    private onUse(): void
    {
   
        if(this.powerInfo.serviceType == 3)
        {
            // 绿通
            // 跳表单
            // this.$router.push({name: "channel",query:{serviceItemId:this.powerInfo.id}});
            this.$router.push({name: "channel",query:{serviceItemId:this.powerInfo.id}});
        }
        else if(this.powerInfo.serviceType == 9)
        {
            // 细胞存储
            this.$router.push({name: "cellStockpile-product-detail",query:{serviceItemId:this.powerInfo.id}});
        }
        else
        {
            // 预约类型
            // this.$router.push({name: "package-list",query:{type: this.packageType[this.powerInfo.serviceType], serviceItemId:this.powerInfo.id}});
        }
        
    }
}
